<template lang="pug">
.ui-tag(v-if="!closable || open" :class="[`_${ type }`, size]")
  span
    slot {{ name }}
  ui-icon(
    v-if="closable"
    clickable
    :size="14"
    :icon="UiIconNames.Icon_Close"
    @click="open = false"
  )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent, ref } from 'vue';
import UiIconNames from "@/components/ui/icon/UiIconNames";

import UiIcon from "@/components/ui/icon/UiIcon.vue";

export default defineComponent({
  name: "UiTag",
  components: {
    UiIcon,
  },
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'success'|'warning'|'danger'|'info'|'default'>,
      default: 'default',
    },
    size: {
      type: String as PropType<'small'|'medium'>,
      default: 'medium',
    },
  },
  setup() {
    const open = ref(true);

    return {
      open,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/tag';
</style>
